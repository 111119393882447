import { api } from '@/plugins/axios'
import router from '@/router'
import { defineStore } from 'pinia'
import { computed, ref } from 'vue'

export const useMiniGameStore = defineStore('miniGame', () => {
  const providers = ref([])
  const provider = computed(() => {
    const providerId = Number(router.currentRoute.value.params.provider)

    return providers.value.find(provider =>provider.id === providerId)
  })

  const game = computed(() => {
    const gameId = Number(router.currentRoute.value.params.game)

    return provider.value?.games.find(game =>game.id === gameId)
  })

  async function fetch () {
    const { data } = await api.get('mini-games')
    providers.value = data.data
  }

  return {
    providers, provider, game, fetch
  }
})
